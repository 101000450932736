import React from 'react'
import { BBVA, Galicia, Macro, Santander, Wibond, AllBanks } from './Modals'
import { AmericanExpress } from './Modals/AmericanExpress'
export const BankModal = ({ type }) => {
  const showContent = () => {
    switch (type) {
      case 'Wibond':
        return <></>
        return <Wibond />
      case 'Banco Macro':
        return <Macro />
      case 'BBVA Banco Frances':
        return <BBVA />
      case 'Galicia':
        return <Galicia />
      case 'Todos los Bancos':
        return <AllBanks />
      case 'Banco Santander':
        return <Santander />
      case 'American Express':
        return <AmericanExpress />
      default:
        return
    }
  }
  return <>{showContent()}</>
}
