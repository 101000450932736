import React, { useState } from 'react'
import { ChevronDown } from 'react-feather'
import Icons from '../../Icons/Icons'
import PropTypes from 'prop-types'
import { CustomLink } from '../../../CustomLink/CustomLink'
import { CheckPathAndLink } from '../../utilities/CheckPathAndLink.js'
import { useSelector } from 'react-redux'
import '../../sidebar.css'
import { BankModal } from '../../../BankModal/BankModal'

const SidebarDropdownItem = ({ item, isLinkActive }) => {
  const { countryId } = useSelector((state) => state.userReducer)
  const [openDrop, setOpenDrop] = useState(false)
  return (
    <>
      <div
        onClick={() => setOpenDrop((prevState) => !prevState)}
        className={`scale d-flex flex-start align-items-center px-3 py-2 mb-4 cursor-pointer ${
          !item.active && 'disabled'
        } ${!isLinkActive && 'disabled'}`}
      >
        <Icons type={item.logo} />
        <span className="mx-2">{item.title}</span>
        <ChevronDown
          width={20}
          className={`tr-500 ${openDrop && 'rotate-180'}`}
        />
      </div>
      <div className={`sb-drop ${openDrop ? 'sb-active' : 'sb-inactive'}`}>
        {item.subItems.map(({ title, active, link }) => {
          if (!link) {
            if (countryId === 1) {
              return <BankModal type={title} key={title} />
            } else {
              return <div key={title}></div>
            }
          }
          if (title === 'Promociones' && countryId !== 1)
            return <div key={title}></div>
          if (title === '¿Qué es SmilePay?' && countryId !== 1)
            return <div key={title}></div>
          // TODO: BORRAR ESTE RETURN CUANDO ASESORES PASE A TODOS LOS COUNTRIES
          if (title === 'Asesores' && countryId !== 1)
            return <div key={title}></div>
          return (
            <div
              key={title}
              className={`text-white position-relative ${
                !active && 'disabled'
              }`}
              to={(active && `${link}`) || ''}
            >
              <CustomLink url={active ? link : ''}>
                <p
                  className={`py-2 my-2 my-1 ${active && 'scale'} ${
                    !CheckPathAndLink(link) && 'disabled'
                  } sidebarItem`}
                >
                  {title}
                </p>
                {!active && (
                  <small className="sb-inactive-text-subitem">
                    ¡Próximamente!
                  </small>
                )}
              </CustomLink>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default SidebarDropdownItem
SidebarDropdownItem.propTypes = {
  item: PropTypes.object.isRequired,
  isLinkActive: PropTypes.bool.isRequired,
}
