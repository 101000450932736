import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import logo from '../../../assets/images/amexlogo.png'
export const AmericanExpress = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)
  const [amount, setAmount] = useState('')
  const [cuotas, setCuotas] = useState('1')
  const [patient, setPatient] = useState('')
  const [type, setType] = useState('')
  const [doctor, setDoctor] = useState('')
  const tokenAut = 'A3CZ9st8dxuATIvLgLpqJiP0Ho3JuoUZlSfxwBdTcU7tv/jbnCdDuQ=='

  useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://www.inngenlabs.com/vexpresstst/js/dinngw.min.js?dummy=2'
    script.async = true
    script.onload = () => setIsScriptLoaded(true)
    script.onerror = () => console.error('Error al cargar el script.')
    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [])

  const pay = () => {
    if (!doctor || !patient || !amount || !type) return
    if (isScriptLoaded && typeof window.envpedido === 'function') {
      const tknprod = btoa(
        JSON.stringify({
          idprod: '1',
          descripcion: `${type} - Paciente: ${patient} - Doctor: ${doctor}`,
          valor: `${amount}.00`,
          cuotas: `${cuotas}`,
        })
      )
      window.envpedido(tokenAut, tknprod, '', 'ref1', 'ref2')
    } else {
      console.warn('La función envpedido no está disponible aún.')
    }
  }

  return (
    <>
      <div className="text-white position-relative">
        <p
          className="py-2 my-2 my-1 cursor-pointer scale disabled sidebarItem"
          onClick={() => setIsOpen(true)}
          id="American Express"
        >
          American Express
        </p>
      </div>
      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen)
          setDoctor('')
          setPatient('')
          setAmount('')
          setCuotas('1')
          setType(null)
        }}
      >
        <hr className="w-100" />
        <ModalBody>
          <div className="d-flex flex-column align-items-center">
            <img
              src={logo}
              alt="amex-logo"
              width={450}
              height={200}
              draggable={false}
            />
            <div
              className="text-center fs-5 fw-bold"
              style={{ lineHeight: '32px' }}
            >
              ¿Qué desea abonar?
            </div>

            <div className="d-flex flex-column text-white align-items-center justify-content-center">
              <div className="d-flex w-100 justify-content-between gap-1 my-3">
                <button
                  className={`btn btn-primary ${
                    type === 'Primer pago' && 'active'
                  } text-center w-25 px-0`}
                  onClick={() => setType('Primer pago')}
                >
                  Primer pago
                </button>
                <button
                  className={`btn btn-primary ${
                    type === 'Segundo pago' && 'active'
                  } text-center w-25 px-0`}
                  onClick={() => setType('Segundo pago')}
                >
                  Segundo pago
                </button>
                <button
                  className={`btn btn-primary ${
                    type === 'Adelanto' && 'active'
                  } text-center w-25 px-0`}
                  onClick={() => setType('Adelanto')}
                >
                  Adelanto
                </button>
                <button
                  className={`btn btn-primary ${
                    type === 'Otros conceptos' && 'active'
                  } text-center w-25 px-0`}
                  onClick={() => setType('Otros conceptos')}
                >
                  Otros conceptos
                </button>
              </div>
              <div className="d-flex flex-column w-100 mt-3 justify-content-start text-primary">
                <label htmlFor="inform">
                  Indique aquí el nombre completo del paciente
                </label>
                <input
                  type="text"
                  className="form-control placeholder-center"
                  name="inform"
                  placeholder="Ej. Juan Perez"
                  value={patient}
                  onChange={({ target }) => setPatient(target.value)}
                />
                <label htmlFor="Informacion" className="text-primary">
                  Coloque aquí nombre y apellido del doctor
                </label>
                <input
                  className="form-control placeholder-center"
                  name="Informacion"
                  type="text"
                  placeholder="Ej. Juana Rodriguez"
                  value={doctor}
                  onChange={({ target }) => setDoctor(target.value)}
                />

                <label htmlFor="amount">
                  Indique el monto (Sin puntos, comas o valores decimales)
                </label>
                <input
                  className="form-control placeholder-center"
                  name="amount"
                  type="text"
                  value={amount}
                  onChange={({ target }) => {
                    if (/^\d*$/.test(target.value)) {
                      setAmount(target.value)
                    }
                  }}
                  placeholder="Ej. 120000"
                />
                <label htmlFor="cuotas">
                  Indique aquí la cantidad de cuotas a realizar el pago
                </label>
                <input
                  type="text"
                  className="form-control placeholder-center"
                  name="cuotas"
                  placeholder="Ej. 6"
                  value={cuotas}
                  onChange={({ target }) => {
                    if (/^\d*$/.test(target.value)) {
                      setCuotas(target.value)
                    }
                  }}
                />
              </div>
            </div>
            <input
              type="button"
              class="boton"
              onClick={pay}
              value="PAGAR"
              disabled={!doctor || !patient || !amount || !type}
            />
          </div>
        </ModalBody>
        <ModalFooter className="fst-italic disabled fs-7 pe-4">
          * Este método de pago es externo a nuestra plataforma
        </ModalFooter>
      </Modal>
    </>
  )
}
